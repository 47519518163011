/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Document, Page, Text, Font, StyleSheet, Link, Image, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { CopyrightCertificate } from '../../assets/icons';
import InterMedium from '../../assets/fonts/Inter-Medium.ttf';
import Colors from '../../styles/Colors';

// Create styles
const styles = StyleSheet.create({
  page: {
    position: 'relative',
  },
  fileName: {
    position: 'absolute',
    top: 210,
    left: '45%',
    color: Colors.primary,
    fontSize: 16,
    fontFamily: 'Inter',
  },
  ipfs: {
    position: 'absolute',
    bottom: 304,
    left: '23.5%',
    color: 'white',
    fontSize: 10,
    fontFamily: 'Inter',
  },
  date: {
    position: 'absolute',
    bottom: 256,
    left: '20.5%',
    color: 'white',
    fontSize: 10,
    fontFamily: 'Inter',
  },
  url: {
    position: 'absolute',
    bottom: 209,
    left: '26%',
    color: 'white',
    fontSize: 10,
    fontFamily: 'Inter',
    fontWeight: 'bold',
  },
  nftid: {
    position: 'absolute',
    bottom: 160,
    left: '24.5%',
    color: 'white',
    fontSize: 10,
    fontFamily: 'Inter',
  },
  qrCode: {
    position: 'absolute',
    bottom: 109,
    right: '7%',
  },
  disclaimer: {
    position: 'absolute',
    bottom: 47,
    left: '20%',
    color: 'white',
    fontSize: 8,
    fontFamily: 'Inter',
    maxWidth: 500,
  },
  disclaimerCnt: {
    position: 'absolute',
    bottom: 35,
    left: '34%',
    color: 'white',
    fontSize: 8,
    fontFamily: 'Inter',
  },
});

Font.register({
  family: 'Inter',
  format: 'truetype',
  src: InterMedium,
});

const Certificate = ({ copyrightedFile }) => {
  const createdAt = format(new Date(copyrightedFile?.createdAt), 'yyyy-MM-dd, kk:mm:ss');
  const name = localStorage.getItem('userName');

  const qrCodeCanvas = document.querySelector('canvas');
  let qrCodeDataUri = '';

  if (qrCodeCanvas) qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 0.3);

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <Image src={CopyrightCertificate} />
        <Text fixed style={styles.fileName}>
          "{copyrightedFile?.fileName}"
        </Text>

        <View fixed style={styles.ipfs}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text>IpfsHash: </Text>
            <Text style={{ color: 'white', marginLeft: 24 }}>{copyrightedFile?.bcHash}</Text>
          </View>
        </View>

        <View fixed style={styles.date}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text>Creation Date: </Text>
            <Text style={{ color: 'white', marginLeft: 24 }}>{createdAt}</Text>
          </View>
        </View>

        <View fixed style={styles.url}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text>URL: </Text>
            <Link
              wrap={false}
              style={{
                color: Colors.primary,
                marginLeft: 24,
              }}
              // eslint-disable-next-line no-underscore-dangle
              src={`${window.location.origin}/verify/${copyrightedFile?._id}`}
            >
              {`${window.location.origin}/verify/${copyrightedFile?._id}`.slice(0, 55)}
              ...
            </Link>
          </View>
        </View>

        <View fixed style={styles.qrCode}>
          <Image src={{ uri: qrCodeDataUri }} style={{ width: 100, height: 100 }} />
        </View>

        <View fixed style={styles.nftid}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text>NFT ID: </Text>
            <Text style={{ color: 'white', marginLeft: 24, marginBottom: 2 }}>
              {copyrightedFile?.nftId}
            </Text>
          </View>
        </View>

        <View wrap style={styles.disclaimer}>
          <Text style={{ textAlign: 'center' }}>
            This transaction validates that {name} uploaded “{copyrightedFile?.fileName}” with the
            digital fingerprint "{copyrightedFile?.txHash}" to Playtreks, proving their possession
            of this file on {createdAt}.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default Certificate;
