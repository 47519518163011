/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import AddToQueueIcon from '../../assets/icons/add_to_queue.svg';
import HelpCenterIcon from '../../assets/icons/helpcenter.png';

import playtreksTransparentBg from '../../assets/icons/playtreksTransparentBg.png';
import { CopyrightMenu, Support } from './menuConfig';
import './index.scss';
import AppSwitcher from '../AppSwitcher/AppSwitcher';

const APP_URL = process.env.REACT_APP_APP_URL;

function SidebarMenu({ headerIcon, headerTitle, menus, path }) {
  return (
    <div className="sidebar-menu">
      <label className="sidebar-menu-header">
        <img src={headerIcon} alt="header" />
        {headerTitle}
      </label>
      <ul>
        {menus.map((item, index) => {
          if (item.onlyMobile) return;

          const isActive = item.url === path;

          return item.url === 'https://support.playtreks.com/' ? (
            // eslint-disable-next-line react/no-array-index-key
            <a href={item.url} key={index} target="_blank" rel="noreferrer">
              <li className={`sidebar-menu-item ${isActive ? 'active' : ''}`}>{item.label}</li>
            </a>
          ) : (
            <Link to={item.url} key={index}>
              <li className={`sidebar-menu-item ${isActive ? 'active' : ''}`}>{item.label}</li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
}

const getDefaultSwitcherState = (location) => {
  if (CopyrightMenu.some((menu) => location.pathname === menu.url)) {
    return 'copyright';
  }
};

const Sidebar = () => {
  const location = useLocation();
  const [selectedDropDown, setSelectedDropDown] = useState('copyright');
  getDefaultSwitcherState(location);

  const handleDropDownValueChange = (event) => {
    const { value } = event.target;
    if (value !== 'copyright') {
      setSelectedDropDown('copyright');
    }
    if (value === 'analytics') {
      window.location.href = `${APP_URL}/artistDashboard`;
    }
    if (value === 'distribution') {
      window.location.href = `${APP_URL}/artistDashboard/myReleases`;
    }
    if (value === 'marketplace') {
      window.location.href = 'https://marketplace.playtreks.com/';
    }
  };

  return (
    <div className="sidebar">
      <Link to="/">
        <h2 className="sidebar-title">
          <img src={playtreksTransparentBg} alt="logo" className="sidebar-logo" />
        </h2>
      </Link>

      <AppSwitcher
        handleDropDownValueChange={handleDropDownValueChange}
        selectedDropDown={selectedDropDown}
      />
      {selectedDropDown === 'copyright' && (
        <>
          <SidebarMenu
            headerIcon={AddToQueueIcon}
            headerTitle="copyright"
            menus={CopyrightMenu}
            path={location.pathname}
          />
        </>
      )}

      <SidebarMenu
        headerIcon={HelpCenterIcon}
        headerTitle="support"
        menus={Support}
        path={location.pathname}
      />
    </div>
  );
};
export default Sidebar;
