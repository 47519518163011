/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import * as Yup from 'yup';
import { FieldArray, Formik } from 'formik';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import PTButton from '../../components/Button/Button';
import PTCard from '../../components/Card/Card';
import PTDropzone from '../../components/DropZone/DropZone';
import PTSelect from '../../components/Select/Select';
import PTTextArea from '../../components/TextArea/TextArea';
import PTTextField from '../../components/TextField/TextField';
import { copyrightFile } from '../../services/copyright';
import { CrossIcon, SpinnerIcon, TrashIcon } from '../../assets/icons';
import { getUserData } from '../../services/user';
import Colors from '../../styles/Colors';
import { Error } from '../../components/TextField/styles';
import { getSubscription } from '../../services/payments';

const filetypeOptions = [
  { label: 'Song', value: 'song' },
  { label: 'Lyrics', value: 'lyrics' },
  { label: 'Beat', value: 'beat' },
  { label: 'Riff', value: 'riff' },
  { label: 'Improvisation', value: 'improvisation' },
  { label: 'Album', value: 'album' },
  { label: 'Idea', value: 'idea' },
  { label: 'Quotation', value: 'quotation' },
  { label: 'Other', value: 'other' },
];

// const fileStageOptions = [
//   { label: 'New', value: 'new' },
//   { label: 'Add to existing version', value: 'existing' },
// ];

const validationSchema = Yup.object().shape({
  fileType: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().required('Required'),
  }),
  // fileStage: Yup.object().shape({
  //   label: Yup.string(),
  //   value: Yup.string().required('Required'),
  // }),
  title: Yup.string().required('Required'),
  file: Yup.mixed()
    .required('File is required')
    .test('fileSize', 'The file is too large. Max 10MB allowed', (value) => {
      if (!value?.length) return true;
      return value[0].size <= 100_000_000; // 10 MB
    }),
});

const initialValues = {
  fileType: '',
  // fileStage: '',
  title: '',
  version: '',
  coCreators: [''],
  note: '',
  file: null,
};

const SelectedFile = styled.div`
  background: ${Colors.colorDarkOne};
  border-radius: 10px;
  border: 2px dashed ${Colors.white050};
`;

const CoCreatorText = styled.span`
  color: ${Colors.primary};
  cursor: pointer;
`;

const CopyrightSong = ({ history }) => {
  const [, setFileUploading] = useState(false);
  const { data: { data: { subscription } = {} } = {} } = useQuery(
    'user-subscription',
    getSubscription,
    {
      refetchOnWindowFocus: true,
    }
  );
  const { data: { data: user = {} } = {} } = useQuery('user', getUserData, {
    refetchOnMount: false,
  });
  const isSubscribed = ['active', 'trialing'].includes(subscription?.state?.status);

  const handleOnSubmit = async (values, formActions) => {
    const { fileType, title, version, note, coCreators = [] } = values;
    setFileUploading(true);
    if (isSubscribed) {
      try {
        const payload = {
          file: new Blob(values.file),
          fileName: values.file[0].name,
          fileType: fileType.value,
          title,
          version,
          note,
          coCreators: coCreators.filter((coCreator) => coCreator),
        };

        const response = await copyrightFile(payload);

        toast.success('Your copyright has been successfully registered!');

        if (response.data) {
          // eslint-disable-next-line no-underscore-dangle
          history.push(`copyrighted-file/${response.data._id}`, response.data);
        }
      } catch (err) {
        console.error(err);
        toast.error(
          err.response.data.message
            ? err.response.data.message
            : 'An unexpected error occured. Please try again!'
        );
        setFileUploading(false);
        formActions.setSubmitting(false);
      }
    } else {
      toast.error(
        'You need an active subscription to register a copyright. Please subscribe to continue.'
      );
    }
  };

  return (
    <>
      <div className="row justify-content-center text-white">
        <PTCard className="col-12 col-sm-10 col-lg-8 px-5">
          <h3 className="mb-4 text-center">
            To register your copyright, you can secure your file using the form below
          </h3>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              values,
              errors,
              touched,
            }) => (
              <div className="row align-items-center justify-content-center ">
                <div className="col-12 mb-3">
                  <PTSelect
                    label="File type"
                    name="fileType"
                    options={filetypeOptions}
                    onChange={(o) => setFieldValue('fileType', o)}
                    onBlur={() => setFieldTouched('fileType', true)}
                    error={touched.fileType && errors.fileType ? errors.fileType.value : ''}
                  />
                </div>

                {/* <div className="col-12 mb-3">
                <PTSelect
                  label="File stage"
                  name="fileStage"
                  options={fileStageOptions}
                  onChange={(o) => setFieldValue('fileStage', o)}
                  onBlur={() => setFieldTouched('fileStage', true)}
                  error={touched.fileStage && errors.fileStage ? errors.fileStage.value : ''}
                />
              </div> */}

                <div className="col-12 mb-3">
                  <PTTextField
                    label="Title"
                    name="title"
                    value={values.title}
                    error={touched.title && errors.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>

                <div className="col-12 mb-3">
                  <PTTextField
                    label="Version"
                    name="version"
                    value={values.version}
                    error={touched.version && errors.version}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="col-12 mb-3">
                  {values.file?.[0]?.name ? (
                    <SelectedFile className="text-center p-3">
                      {values.file?.[0]?.name}{' '}
                      <span
                        role="button"
                        className="ml-2 cursor-pointer"
                        onClick={() => setFieldValue('file', null)}
                      >
                        <CrossIcon />
                      </span>
                    </SelectedFile>
                  ) : (
                    <PTDropzone
                      onAccept={(file) => setFieldValue('file', file)}
                      // onReject={handleRejectTrack}
                      // disabled={dropzoneIsUploading}
                      className="py-4"
                    >
                      <p className="text-muted">Drag and Drop here to upload your file</p>
                      <PTButton label="Upload your file">Save</PTButton>
                    </PTDropzone>
                  )}
                  {touched.file && errors.file && <Error className="mt-2">{errors.file}</Error>}
                </div>
                <div className="col-12 mb-3">
                  <PTTextField
                    label="Created By"
                    name="createdBy"
                    disabled
                    value={`${user?.firstname} ${user?.lastname}`}
                  />
                </div>

                {/* Co-Creator(s) */}
                <div className="col-12  mb-3">
                  <FieldArray name="coCreators">
                    {(fieldArrayProps) => {
                      const { push, remove, form } = fieldArrayProps;
                      const { coCreators } = form.values;
                      return (
                        <>
                          {coCreators.map((coCreator, index) => (
                            <div className="row mb-3 align-items-center">
                              <div key={index} className="col">
                                <PTTextField
                                  label="Co-Creator(s)"
                                  name={`coCreators[${index}]`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={coCreator}
                                />
                              </div>
                              {/* Remove co-creators */}

                              {index > 0 && (
                                <div className="col-1 px-0 px-md-3" onClick={() => remove(index)}>
                                  <span className="cursor-pointer">
                                    <TrashIcon alt="delete co-creators" />
                                  </span>
                                </div>
                              )}
                            </div>
                          ))}
                          {/* Add co-creators */}
                          <div
                            div
                            className="row mx-0 mt-3 justify-content-end align-items-center "
                          >
                            <div onClick={() => push('')} className="mb-3">
                              <CoCreatorText className="rounded bg-dark p-2 px-3 mr-2">
                                +
                              </CoCreatorText>
                              <CoCreatorText>Add Co-Creator(s)</CoCreatorText>
                            </div>
                          </div>
                        </>
                      );
                    }}
                  </FieldArray>
                </div>

                <div className="col-12 mb-3">
                  <PTTextArea
                    label="Note"
                    name="note"
                    value={values.note}
                    onChange={handleChange}
                  />
                </div>

                <PTButton
                  primary
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  label={isSubmitting ? 'Copyrighting your file...' : 'Copyright it!'}
                  endIcon={isSubmitting && <SpinnerIcon width="24" height="24" />}
                />
              </div>
            )}
          </Formik>
        </PTCard>
      </div>
    </>
  );
};

export default CopyrightSong;
