import React from 'react';

import './index.scss';

// eslint-disable-next-line react/prop-types
const AppSwitcher = ({ handleDropDownValueChange, selectedDropDown }) => (
  <div className="px-md-3 ml-4 ml-md-0 mb-4">
    <select
      // eslint-disable-next-line max-len
      className="app-switcher-select w-100  text-dark p-3 py-md-2 cursor-pointer rounded"
      value={selectedDropDown}
      onChange={handleDropDownValueChange}
    >
      <option value="analytics">Data Dashboard</option>
      <option value="distribution">Music Distribution</option>
      <option value="marketplace">Marketplace</option>
      <option value="copyright">Copyright Control</option>
    </select>
  </div>
);

export default AppSwitcher;
