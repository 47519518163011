/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { myfile } from '../../services/myfiles';
import './index.scss';
import GlobalFilter from './GlobalFilter';
import { PaginationLeftIcon, PaginationRightIcon, SpinnerIcon } from '../../assets/icons';
import getColumns from './Columns';
import { deleteCopyrightedFile } from '../../services/copyright';
import MyfilesHeader from './MyfilesHeader';

const CopyrightTable = () => {
  const {
    data: { data: myfiles } = {},
    isLoading: loading,
    refetch,
  } = useQuery('myfiles', myfile, {
    refetchOnMount: false,
  });

  const handleDeleteRow = async (fileId) => {
    if (window.confirm('Are you sure you want to delete this file?')) {
      try {
        await deleteCopyrightedFile(fileId);

        toast.success('Your copyright has been successfully deleted!');
        refetch();
      } catch (err) {
        toast.error('Something went wrong. Please try again!');
      }
    }
  };

  const myFilesData = useMemo(() => (!myfiles ? [] : [...myfiles]), [myfiles]);

  const columns = useMemo(() => getColumns({ onDeleteRow: handleDeleteRow }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    setPageSize,
    state,
    prepareRow,
    setGlobalFilter,
    preGlobalFilteredRows,
  } = useTable(
    {
      columns,
      data: myFilesData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <>
      <MyfilesHeader className="mb-3" />
      <div className="myFilesContainer">
        <p className="font-weight-bold text-white mb-2">Filter by:</p>
        <div className="row pb-2 align-items-center justify-content-between">
          <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
            preGlobalFilteredRows={preGlobalFilteredRows}
          />
        </div>
        <div className="table-view files">
          <table {...getTableProps()} className="copyright-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column?.getSortByToggleProps?.())}
                      className="p-3"
                    >
                      {column.render('Header')}
                      <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
              {loading && (
                <tr>
                  <td colSpan={columns.length} className="p-3 text-center">
                    <div className="dashboard-overview-streaming-stats-spinner-wrapper">
                      <SpinnerIcon />
                    </div>
                  </td>
                </tr>
              )}
              {page.length && !loading
                ? page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td title={cell.value} {...cell.getCellProps()} className="p-3">
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                : !loading && (
                    <tr>
                      <td colSpan={columns.length} className="p-3 text-center">
                        No data
                      </td>
                    </tr>
                  )}
            </tbody>
          </table>
          {/* pagination */}

          <div
            className="row justify-content-center mx-1 mt-3 position-sticky p-3 "
            style={{ bottom: 0, background: '#1d2025' }}
          >
            <div className="pagination text-white">
              <button
                type="button"
                className="text-white bg-transparent rounded mr-2"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <PaginationLeftIcon width={16} height={16} opacity={canPreviousPage ? 1 : 0.3} />
              </button>
              <span className="mr-2">
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageCount}
                </strong>{' '}
              </span>
              <button
                type="button"
                className="mr-3 text-white bg-transparent rounded"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <PaginationRightIcon width={16} height={16} opacity={canNextPage ? 1 : 0.3} />
              </button>

              <select
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                className="text-white bg-transparent border rounded mr-2"
              >
                {[10, 25, 50, 100].map((size) => (
                  <option key={size} value={size} className="bg-dark">
                    Show {size}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CopyrightTable;
