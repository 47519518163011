import React, { useState } from 'react';
import * as S from './styles';

const PTSelect = (props) => {
  const [input, setInput] = useState('');
  const [value, setValue] = useState('');
  const { placeholder, label, name, error, forwardedRef, onInputChange, onChange, ...rest } = props;

  const handleOnChange = (selectedOption, e) => {
    setValue(selectedOption);
    onChange?.(selectedOption, e);
  };

  const handleOnInputChange = (i) => {
    setInput(i);
    onInputChange?.(i);
  };

  return (
    <S.SelectComponent>
      <S.Label value={value} input={input} label={label} htmlFor={name}>
        {label}
      </S.Label>
      <S.StyledSelect
        {...rest}
        components={{ DropdownIndicator: S.DropdownIndicator, IndicatorSeparator: null }}
        classNamePrefix="PTSelect"
        input={input}
        value={value}
        label={label}
        placeholder={label || placeholder || ''}
        onInputChange={handleOnInputChange}
        onChange={handleOnChange}
      />

      {error && <S.Error>{error}</S.Error>}
    </S.SelectComponent>
  );
};

export default PTSelect;
