const Colors = {
  primary: '#0ef1db',
  secondary: '#a5aaa8',
  white: '#fff',

  siteBackground: '#343a47',
  darkerbackground: '#383b3a',
  background: '#3d403f',

  colorDarkOne: '#1d2025',
  colorDarkTwo: '#343947',
  colorLightOne: '#33373f',
  colorLightTwo: '#8e8f92',

  modalOverlay: '#343947B3',

  blac10: '#2c313a',
  gray40: '#4C4C51',
  gray05: 'hsla(0, 0%, 100%, 0.05)',

  black012: 'rgba(0, 0, 0, 0.12)',
  black050: 'rgba(0, 0, 0, 0.50)',
  white050: 'rgba(255, 255, 255, 0.50)',

  error: '#f04467',
};

export default Colors;
