import axios from 'axios';
import { getJWT } from '../shared/utils';

// eslint-disable-next-line prefer-destructuring
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export function createStripeSession(redirectURL = window.location.origin) {
  return axios.post(
    `${API_BASE_URL}/payments/stripe/payment_method`,
    {
      // eslint-disable-next-line max-len
      return_url: `${redirectURL}/subscriptions?pay=true&plan=playtreks_copyright_unlimited_monthly_plan&processor=stripe`,
      copyright: true,
    },
    {
      headers: {
        Authorization: `Bearer ${getJWT()}`,
      }, // <== in case of post request, authorization has to be passed as 3rd argument
    }
  );
}

export function getSavedCard() {
  return axios.get(
    `${API_BASE_URL}/payments/stripe/payment_method`,

    {
      headers: {
        Authorization: `Bearer ${getJWT()}`,
      }, // <== in case of post request, authorization has to be passed as 3rd argument
    }
  );
}

export function performStripePayment(payload) {
  return axios.post(
    `${API_BASE_URL}/copyrightControl/stripe/perform_payment`,
    { subscriptionPlan: payload.subscriptionPlan },
    {
      headers: {
        Authorization: `Bearer ${getJWT()}`,
      },
    }
  );
}

export function getSubscription() {
  return axios.get(`${API_BASE_URL}/copyrightControl/subscription`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
}

export function cancelSubscription(payload) {
  return axios.post(
    `${API_BASE_URL}/copyrightControl/subscription/cancel`,
    { subscriptionPlan: payload.subscriptionPlan },
    {
      headers: {
        Authorization: `Bearer ${getJWT()}`,
      },
    }
  );
}
