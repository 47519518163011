import React, { useState } from 'react';

import * as S from './styles';

const PTTextArea = (props) => {
  const { name, onChange, rows = 4, placeholder, forwardedRef, error, label, ...rest } = props;
  const [value, setValue] = useState('');

  const handleOnChange = (e) => {
    setValue(e.target.value);
    onChange?.(e);
  };

  return (
    <S.TextAreaComponent>
      <S.Label value={value} label={label} htmlFor={name}>
        {label}
      </S.Label>

      <S.TextArea
        {...rest}
        name={name}
        rows={rows}
        placeholder={label || placeholder || ''}
        value={value}
        onChange={handleOnChange}
      />
      {error && <S.Error>{error}</S.Error>}
    </S.TextAreaComponent>
  );
};

export default PTTextArea;
