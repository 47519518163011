import React from 'react';
import MobileMenuBurger, { MobileMenu } from '../MobileMenu/MobileMenu';
import UserProfile from '../userprofile/UserProfile';
import './index.scss';

const DashBoardHeader = () => (
  <section className="header-container-header">
    <div className="header-container-header-top">
      <div className="header-container-header-top-right">
        <UserProfile />
        <MobileMenu />
        <MobileMenuBurger />
      </div>
    </div>
  </section>
);

export default DashBoardHeader;
