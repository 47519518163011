import axios from 'axios';
import { getJWT } from '../shared/utils';

// eslint-disable-next-line prefer-destructuring
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const myfile = async () => {
  const url = `${API_BASE_URL}/copyrightControl/copyrightFiles`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
};
