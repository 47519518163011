import Cookies from 'js-cookie';

export const getJWT = () => Cookies.get('jwtToken');

export const getQueryParam = (param, queryString = window.location.search) => {
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(param);
};

export const FREE_PLAN = 'free-plan';
export const UNLIMITED_PLAN = 'unlimited-plan';

export const SubscriptionPlansConfig = {
  playtreks_copyright_free_plan: {
    name: 'Free',
    id: FREE_PLAN,
    price: 0,
    card_index: 0,
  },
  playtreks_copyright_unlimited_monthly_plan: {
    name: 'Unlimited Plan',
    id: UNLIMITED_PLAN,
    price: 10,
    card_index: 1,
  },
};
