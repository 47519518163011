import { PDFViewer } from '@react-pdf/renderer';
import React from 'react';
import { useQuery } from 'react-query';
import QRCode from 'qrcode.react';
import { SpinnerIcon } from '../../assets/icons';
import Certificate from '../../components/Certificate/Certificate';
import { getCopyrightedFile } from '../../services/copyright';
import { Card } from '../CopyrightFileDetailView';

const ViewCertificate = ({ match }) => {
  const { id: fileId } = match.params;
  const { data: { data: copyrightedFile = {} } = {}, isLoading } = useQuery(
    fileId,
    getCopyrightedFile.bind(this, fileId)
  );

  if (isLoading) {
    return (
      <Card className="row align-items-center justify-content-center h-100vh w-100">
        <SpinnerIcon width={100} height={100} />
      </Card>
    );
  }

  return (
    <>
      <QRCode
        style={{ display: 'none' }}
        level="Q"
        value={`${window.location.origin}/verify/${copyrightedFile?._id}`}
      />
      <PDFViewer style={{ width: '100%', minHeight: '99vh' }}>
        <Certificate copyrightedFile={copyrightedFile} />
      </PDFViewer>
    </>
  );
};

export default ViewCertificate;
