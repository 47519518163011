import styled, { css } from 'styled-components';

const variants = (props) => {
  if (props.primary) {
    return css`
      color: #212529;
      background: #0ef1db;
    `;
  }

  if (props.outlined) {
    return css`
      color: #fff;
      background: transparent;
      border: 1px solid #0ef1db;

      :hover {
        color: #212529;
        background: #fff;
      }
    `;
  }

  // default variant
  return css`
    color: #0ef1db;
    background: #33383f;
  `;
};

const sizing = (props) => {
  if (props.small) {
    return css`
      font-size: 0.875rem;
      border-radius: 6px;
      padding: 0.625rem 1.25rem;
    `;
  }

  if (props.large) {
    return css`
      font-size: 1.25rem;
      border-radius: 10px;
      padding: 1rem 1.25rem;
    `;
  }

  // default size as medium
  return css`
    font-size: 1rem;
    border-radius: 8px;
    padding: 0.875rem 1.25rem;
  `;
};

const Button = styled.button`
  border: none;
  text-align: center;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.5;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  transition: all 0.15s ease;
  will-change: transform;
  outline: none;

  :active,
  :focus {
    outline: none;
  }

  :hover {
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    transform: translateY(-1px);
  }

  ${sizing}
  ${variants};
`;

export default Button;
