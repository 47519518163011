import React from 'react';
import { ExternalLinkIcon } from '../../assets/icons';

const AUTH_URL = process.env.REACT_APP_AUTH_REDIRECT_URL;
const APP_URL = process.env.REACT_APP_APP_URL;

export const CopyrightMenu = [
  {
    label: 'add new',
    url: '/copyright-song',
  },
  {
    label: 'my files',
    url: '/my-files',
  },
  {
    label: 'subscriptions',
    url: '/subscriptions',
  },
];

export const Support = [
  {
    label: (
      <div className="row col justify-content-between">
        help center
        <span>
          <ExternalLinkIcon width={16} height={16} />
        </span>
      </div>
    ),
    url: 'https://support.playtreks.com/',
  },
];
export const UserMenu = [
  {
    label: 'account settings',
    url: `${APP_URL}/artistDashboard/profile`,
  },
  {
    label: 'subscription settings',
    url: `${APP_URL}/artistDashboard/subscriptions`,
  },
  {
    label: 'wallet details',
    url: 'https://marketplace.playtreks.com/wallet',
  },
  {
    label: 'logout',
    url: `${AUTH_URL}/logout`,
  },
];
