import React, { useMemo } from 'react';

import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import Colors from '../../styles/Colors';

const baseStyle = {
  transition: 'border .24s ease-in-out',
  borderStyle: 'dashed',
  borderColor: Colors.white050,
};

const activeStyle = {
  borderStyle: 'dashed',
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderStyle: 'dashed',
  borderColor: Colors.primary,
};

const rejectStyle = {
  borderStyle: 'dashed',
  borderColor: Colors.error,
};

const DropZoneContainer = styled.div`
  cursor: pointer;
  width: 100%;
  color: ${Colors.white};
  background: ${Colors.colorDarkOne};
  border-radius: 10px;
  padding: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const PTDropzone = (props) => {
  const { children, acceptFileType, onReject, onAccept, disabled } = props;
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: acceptFileType,
    onDropRejected: onReject,
    onDropAccepted: onAccept,
    disabled,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <DropZoneContainer {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {children}
    </DropZoneContainer>
  );
};

export default PTDropzone;
