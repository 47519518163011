import axios from 'axios';
import { getJWT } from '../shared/utils';

// eslint-disable-next-line prefer-destructuring
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const copyrightFile = async (payload) => {
  if (!payload.file) {
    throw new Error('Select a file first!');
  }

  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('fileName', payload.fileName);
  formData.append('fileType', payload.fileType);
  formData.append('title', payload.title);
  formData.append('version', payload.version);
  formData.append('note', payload.note);
  formData.append('coCreators', JSON.stringify(payload.coCreators));

  const url = `${API_BASE_URL}/copyrightControl/copyrightFile`;

  return axios.post(url, formData, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getCopyrightedFile = async (fieldId) => {
  const url = `${API_BASE_URL}/copyrightControl/copyrightFile/${fieldId}`;

  return axios.get(url);
};

export const deleteCopyrightedFile = async (fieldId) => {
  const url = `${API_BASE_URL}/copyrightControl/copyrightFile/${fieldId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
};
