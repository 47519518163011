import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Components
import SideMenu from './components/sidemenu/SideMenu';
import Header from './components/header/Header';

// Pages
import CopyrightSong from './pages/CopyrightSong';
import CopyrightFileDetailView from './pages/CopyrightFileDetailView';
import MyFiles from './pages/MyFiles';
import CopyrightVerify from './pages/CopyrightVerify';
import CopyrightPublicSong from './pages/CopyrightPublicSong';

// Utils
import { getJWT } from './shared/utils';
import ViewCertificate from './pages/ViewCertificate';
import Subscription from './pages/Subscription';

const Container = styled.div`
  margin-left: 227px;
  padding: 1.5rem;

  @media (max-width: 768px) {
    margin-left: 0;
    padding: -1px;
  }
`;

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      getJWT() ? (
        <Container>
          <SideMenu />
          <Header />
          <Component {...props} />
        </Container>
      ) : (
        window.location.assign(
          `${process.env.REACT_APP_AUTH_REDIRECT_URL}?redirect_uri=${window.location.href}`
        )
      )
    }
  />
);

function App() {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Router>
        <Switch>
          <PrivateRoute path="/" component={() => <Redirect to="my-files" />} exact />
          <PrivateRoute path="/copyright-song" component={CopyrightSong} />
          <PrivateRoute path="/copyrighted-file/:id" component={CopyrightFileDetailView} />
          <PrivateRoute path="/my-files" component={MyFiles} />
          <PrivateRoute path="/subscriptions" component={Subscription} />

          <Route path="/verify/:id" exact component={CopyrightVerify} />
          <Route path="/certificates/:id" exact component={ViewCertificate} />
          <Route path="/song/:id" exact component={CopyrightPublicSong} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
