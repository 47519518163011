/* eslint-disable jsx-a11y/anchor-is-valid */
import { PDFDownloadLink } from '@react-pdf/renderer';
import { format } from 'date-fns';
import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  certificateIcon,
  PTTransparentIcon,
  SpinnerIcon,
  VerifyBlockchainIcon,
} from '../../assets/icons';
import Certificate from '../../components/Certificate/Certificate';
import { getCopyrightedFile } from '../../services/copyright';
import Colors from '../../styles/Colors';

const Wrapper = styled.div`
  background: ${Colors.colorDarkOne};
  border-radius: 14px;
  overflow-wrap: break-word;
`;

const Action = styled.div`
  color: ${Colors.colorLightTwo};

  a {
    color: ${Colors.colorLightTwo};
  }

  &:hover {
    color: ${Colors.primary};

    a {
      color: ${Colors.primary};
    }
  }
`;

const Image = styled.img`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
`;

const CopyrightVerify = ({ match }) => {
  const { id: fileId } = match.params;
  const { data: { data: copyrightedFile = {} } = {}, isLoading } = useQuery(
    fileId,
    getCopyrightedFile.bind(this, fileId)
  );

  const certificateFileName = `${
    copyrightedFile?.fileName?.split('.')[0]
  }-copyright-certificate.pdf`;

  if (isLoading) {
    return (
      <div className="row align-items-center justify-content-center h-100vh w-100">
        <SpinnerIcon width={100} height={100} />
      </div>
    );
  }
  return (
    <div className="m-3">
      <div className="row mx-0 justify-content-center align-items-center mb-4">
        <Image width={200} src={PTTransparentIcon} alt="playtreks-logo" className="img-fluid" />
      </div>

      <div className="row  justify-content-center">
        <Wrapper className="col-11 col-lg-9 col-xl-8 text-center p-3">
          {copyrightedFile?.fileType === 'song' ? (
            <h4 className="pt-3 text-muted text-center">
              This song is registered and copyrighted by copyright.playtreks.com
            </h4>
          ) : (
            <h4 className="pt-3 text-muted text-center">
              This production is registered and copyrighted by copyright.playtreks.com
            </h4>
          )}
          <h2 className="text-center text-white font-weight-bold pt-4">{copyrightedFile?.title}</h2>

          {/* Created by */}
          <div className="pt-3 text-center">
            <span className="color-gray10 font-weight-bold ">Created by:</span>{' '}
            <span className=" text-white">{copyrightedFile?.createdBy}</span>
          </div>

          {/* version */}
          {copyrightedFile?.version ? (
            <div className="pt-3 text-center">
              <span className="color-gray10 font-weight-bold ">Version:</span>{' '}
              <span className=" text-white">{copyrightedFile?.version}</span>
            </div>
          ) : (
            ''
          )}

          {/* fileType */}

          <div className="pt-3 text-center">
            <span className="color-gray10 font-weight-bold ">File type:</span>{' '}
            <span className=" text-white">{copyrightedFile?.fileType}</span>
          </div>

          {/* digital fingerprint */}
          <div className="pt-3 text-center">
            <span className="color-gray10 font-weight-bold ">Digital fingerprint:</span>{' '}
            <span className=" text-white  ">{copyrightedFile?.txHash}</span>
          </div>

          {/* Registration date */}
          <div className="pt-3 text-center">
            <span className="color-gray10 font-weight-bold ">Registration date:</span>{' '}
            <span className=" text-white ">
              {format(new Date(copyrightedFile?.createdAt), 'yyyy-MM-dd, kk:mm:ss')}
            </span>
          </div>

          {/* NFT id */}
          <div className="pt-3 text-center">
            <span className="color-gray10 font-weight-bold ">NFT Id:</span>{' '}
            <span className=" text-white ">{copyrightedFile?.nftId}</span>
          </div>

          {/* External links */}
          <div className="row pt-2 justify-content-center">
            <div className="col-3 text-center m-3">
              <Action className="cursor-pointer">
                {window.innerWidth > 769 ? (
                  <Link to={`/certificates/${copyrightedFile._id}`} target="_blank">
                    <Image
                      size={56}
                      src={certificateIcon}
                      alt="View Certificate"
                      className="img-fluid"
                    />
                    <div className="d-block mt-3">View certificate!</div>
                  </Link>
                ) : (
                  <PDFDownloadLink
                    document={<Certificate copyrightedFile={copyrightedFile} />}
                    fileName={certificateFileName}
                  >
                    {({ loading }) =>
                      loading ? (
                        'Loading certificate...'
                      ) : (
                        <>
                          <Image size={56} src={certificateIcon} alt="Download original file" />
                          <div className="d-block mt-3">Download certificate!</div>
                        </>
                      )
                    }
                  </PDFDownloadLink>
                )}
              </Action>
            </div>
            <div className="col-3 text-center m-3">
              <Action className="cursor-pointer">
                <a
                  href={`https://polygonscan.com/tx/${copyrightedFile?.txHash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    size={56}
                    src={VerifyBlockchainIcon}
                    alt="View on blockchain"
                    className="img-fluid"
                  />
                  <div className="d-block mt-3">View on blockchain</div>
                </a>
              </Action>
            </div>
          </div>

          {/* footer */}
          <Link to="/copyright-song">
            <h2 className="mt-5 text-center">Want to copyright your own music? click here!</h2>
          </Link>
        </Wrapper>
      </div>

      <Action>
        <Link to="/">
          <p className="text-center  overflow-hidden">Powered by copyright.playtreks.com</p>
        </Link>
      </Action>
    </div>
  );
};

export default CopyrightVerify;
