/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import { SearchIcon } from '../../assets/icons';

import PTTextField from '../../components/TextField/TextField';

import './index.scss';

const GlobalFilter = ({ filter, setFilter, preGlobalFilteredRows }) => {
  const [value, setValue] = useState(filter);
  const count = preGlobalFilteredRows?.length;
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 200);
  return (
    <div className="col-12 col-md-5 col-lg-3 py-2 py-md-0 mb-3">
      <PTTextField
        value={value}
        startIcon={<SearchIcon width="10" height="10" />}
        name="title"
        placeholder={`Search in ${count} records...`}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        inputBlurCallback={() => {}}
      />
    </div>
  );
};

export default GlobalFilter;
