/* eslint-disable jsx-a11y/anchor-is-valid */
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  certificateIcon,
  DownloadIcon,
  SpinnerIcon,
  VerifyBlockchainIcon,
} from '../../assets/icons';
import PTCard from '../../components/Card/Card';
import { getCopyrightedFile } from '../../services/copyright';
import Colors from '../../styles/Colors';
import PTTextField from '../../components/TextField/TextField';
import PTButton from '../../components/Button/Button';
import { getUserData } from '../../services/user';
import Certificate from '../../components/Certificate/Certificate';

// TODO: Move all these to common components and make generic
const Divider = styled.div`
  width: 100%;
  background: ${Colors.primary};
  opacity: 0.4;
  height: 2px;
`;

export const Card = styled(PTCard)`
  /* min-height: 75vh; */
  background: ${Colors.colorDarkOne};
`;

const Action = styled.div`
  color: ${Colors.colorLightTwo};

  a {
    color: ${Colors.colorLightTwo};
  }

  &:hover {
    color: ${Colors.primary};

    a {
      color: ${Colors.primary};
    }
  }
`;

const Image = styled.img`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
`;

const CopyrightFileDetailView = ({ match }) => {
  const { id: fileId } = match.params;
  const { data: { data: copyrightedFile = {} } = {}, isLoading } = useQuery(
    fileId,
    getCopyrightedFile.bind(this, fileId)
  );
  const { data: { data: user = {} } = {} } = useQuery('user', getUserData);

  const [copySuccess, setCopySuccess] = useState('');

  const certificateFileName = `${
    copyrightedFile?.fileName?.split('.')[0]
  }-copyright-certificate.pdf`;

  const copyToClipBoard = (copyMe) => {
    try {
      navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  if (isLoading) {
    return (
      <Card className="row align-items-center justify-content-center h-100vh w-100">
        <SpinnerIcon width={100} height={100} />
      </Card>
    );
  }

  const createdBy = `${user?.firstname || ''} ${user?.lastname || ''}`;

  return (
    <div className="row justify-content-center mt-3">
      <PTCard className="text-white col-md-10 col-lg-9 col-xl-8">
        <h2 className="text-center">{copyrightedFile?.title}</h2>

        <Card className="m-3 text-center">
          <Divider />
          <div>
            <div className="row p-2">
              <div className="col color-gray10">Creation data:</div>
              <div className="col">
                {copyrightedFile?.createdAt &&
                  format(new Date(copyrightedFile?.createdAt), 'yyyy-MM-dd, kk:mm:ss')}
              </div>
            </div>{' '}
            <div className="row p-2">
              <div className="col color-gray10">Created by:</div>
              <div className="col text-truncate">{createdBy.trim()}</div>
            </div>{' '}
            <div className="row p-2">
              <div className="col color-gray10">File name:</div>
              <div className="col text-truncate">{copyrightedFile?.fileName}</div>
            </div>
            <div className="row p-2">
              <div className="col color-gray10">File type:</div>
              <div className="col">{copyrightedFile?.fileType}</div>
            </div>
            <div className="row p-2">
              <div className="col color-gray10">Digital fingerprint:</div>
              <div className="col text-truncate" title={copyrightedFile?.txHash}>
                {copyrightedFile?.txHash}
              </div>
            </div>
          </div>
          <Divider />
        </Card>

        <Card className="m-3">
          <div className="row">
            <div className="col text-center">
              <Action className="cursor-pointer">
                <a href={copyrightedFile?.file}>
                  <Image
                    size={56}
                    src={DownloadIcon}
                    alt="Download original file"
                    className="img-fluid"
                  />
                  <p className="mt-3"> Download original file</p>
                </a>
              </Action>
            </div>
            <div className="col text-center">
              <Action className="cursor-pointer">
                {window.innerWidth > 769 ? (
                  <Link to={`/certificates/${copyrightedFile._id}`} target="_blank">
                    <Image
                      size={56}
                      src={certificateIcon}
                      alt="View Certificate"
                      className="img-fluid"
                    />
                    <div className="d-block mt-3">View certificate!</div>
                  </Link>
                ) : (
                  <PDFDownloadLink
                    document={<Certificate copyrightedFile={copyrightedFile} />}
                    fileName={certificateFileName}
                  >
                    {({ loading }) =>
                      loading ? (
                        'Loading certificate...'
                      ) : (
                        <>
                          <Image size={56} src={certificateIcon} alt="Download original file" />
                          <div className="d-block mt-3">Download certificate!</div>
                        </>
                      )
                    }
                  </PDFDownloadLink>
                )}
              </Action>
            </div>
            <div className="col text-center">
              <Action className="cursor-pointer">
                <a
                  href={`https://polygonscan.com/tx/${copyrightedFile?.txHash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    size={60}
                    src={VerifyBlockchainIcon}
                    alt="View on blockchain"
                    className="img-fluid"
                  />
                  <p className=" d-block mt-3">View on blockchain</p>
                </a>
              </Action>
            </div>
          </div>
        </Card>

        <Card className="m-3">
          <div className="row   justify-content-center ">
            <div className=" col text-center ">
              {copyrightedFile?.fileType === 'song' ? (
                <h4 className="color-gray10 font-weight-bold pb-2 ">SHARE THIS SONG:</h4>
              ) : (
                <h4 className="color-gray10 font-weight-bold pb-2 ">SHARE THIS PRODUCTION:</h4>
              )}
              {copyrightedFile?.fileType === 'song' ? (
                <p className=" text-white">
                  Share this song with your friends by using the URL below:
                </p>
              ) : (
                <p className=" text-white">
                  Share this production with your friends by using the URL below:
                </p>
              )}
              <PTTextField
                value={`${window.location.origin}/song/${fileId}`}
                className="w-50 mb-2 bg-dark"
                readOnly
              />
              <div className="row  justify-content-center align-items-center text-center py-2">
                <div className="pr-2 py-2">
                  <Link to={`/song/${fileId}`} target="_blank">
                    <PTButton label="Preview" />
                  </Link>
                </div>
                <div>
                  <PTButton
                    label="Copy url"
                    onClick={() => copyToClipBoard(`${window.location.origin}/verify/${fileId}`)}
                  />
                </div>
              </div>
              <div className="col color-primary ">{copySuccess}</div>
            </div>
          </div>
        </Card>
      </PTCard>
    </div>
  );
};

export default CopyrightFileDetailView;
