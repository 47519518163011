/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import styled from 'styled-components';
import Select, { components } from 'react-select';

import Colors from '../../styles/Colors';
import { ReactComponent as SelectDropDownIcon } from '../../assets/icons/selectInputArrow.svg';

export const SelectComponent = styled.div`
  position: relative;
`;

export const Label = styled.label`
  visibility: ${({ value, label, input }) => (label && (value || input) ? 'visible' : 'hidden')};
  position: absolute;
  left: 10px;
  top: ${({ value, label, input }) => (label && (value || input) ? '4px' : '16px')};
  font-size: ${({ value, label, input }) => (label && (value || input) ? '12px' : '16px')};
  color: rgb(255, 255, 255, 0.63);
  margin: 0;
  z-index: 99;
  transition: top, font-size 0.25s;
`;

export const Error = styled.p`
  font-size: 12px;
  line-height: 1.5;
  color: ${Colors.error};
  position: relative;
  height: 20px;
  margin: 5px 0 0 3px;
`;

export const StyledSelect = styled(Select)`
  min-width: 100px;
  width: 100%;

  .PTSelect__control {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 34px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    background-color: ${Colors.colorDarkOne};
    border-radius: 10px;
    border: none;
    height: 56px;

    &:hover,
    &:active,
    &:focus {
      color: white;
    }

    &.PTSelect__control--is-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.PTSelect__control--is-focused {
      border: 1px solid ${Colors.primary} !important;
      box-shadow: none;
    }
  }

  .PTSelect__value-container {
    padding-top: ${({ value, label, input }) => (label && (value || input) ? '10px' : '6px')};
  }

  .PTSelect__input {
    color: ${Colors.white} !important;
  }

  .PTSelect__single-value {
    color: ${Colors.white} !important;
  }

  .PTSelect__menu {
    height: 194px;
    background-color: ${Colors.colorDarkOne};
    box-shadow: 0px 2px 14px rgb(0, 0, 0);
    border-radius: 10px !important;
    margin-top: 8px;
    padding: 14px 14px 14px 0;
    color: ${Colors.white};
    z-index: 20;
  }

  .PTSelect__menu-list {
    overflow-y: scroll;
    height: 100%;

    ::-webkit-scrollbar {
      width: 2px !important;
    }

    ::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.2) !important;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.44) !important;
      margin: 2px !important;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555 !important;
      width: 20px !important;
    }
  }

  .PTSelect__option {
    line-height: 20px;
    border-radius: 0 10px 10px 0;
    color: ${Colors.white};
    width: 90%;
    height: '10px';
    paddingbottom: '29px';
    fontsize: '14px';

    :hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .PTSelect__option--is-focused {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .PTSelect__option--is-selected {
    background-color: rgba(255, 255, 255, 0.1);
    color: ${Colors.white};
  }
`;

export const StyledChevronDown = styled(SelectDropDownIcon)`
  margin: 0 8px;
`;

export const DropdownIndicator = (props) =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <StyledChevronDown stroke={Colors.primary} />
    </components.DropdownIndicator>
  );
