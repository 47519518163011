import styled from 'styled-components';

export const PaymentMethod = styled.button`
  width: 285px;
  height: 56px;
  background-color: rgba(51, 55, 63, 0.5);
  border-radius: 10px;
  margin: auto auto 12px;
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;

  :active,
  :focus {
    outline: none;
  }

  &:hover {
    background-color: rgba(51, 55, 63, 0.8);
  }
`;
