import styled from 'styled-components';

const PTCard = styled.div`
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: hsla(0, 0%, 100%, 0.05);
  background-clip: border-box;
  border-radius: 0.625rem;
  padding: 1rem;
`;

export default PTCard;
