/* eslint-disable no-nested-ternary */
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import { CreditCardIcon, SpinnerIcon } from '../../assets/icons';
import PTButton from '../../components/Button/Button';
import PTCard from '../../components/Card/Card';
import Modal from '../../components/Modal/Modal';
import {
  cancelSubscription,
  createStripeSession,
  getSavedCard,
  getSubscription,
  performStripePayment,
} from '../../services/payments';
import {
  FREE_PLAN,
  getQueryParam,
  SubscriptionPlansConfig,
  UNLIMITED_PLAN,
} from '../../shared/utils';
import Colors from '../../styles/Colors';
import './index.scss';
import { PaymentMethod } from '../CopyrightSong/styles';

const Title = styled.h1`
  font-size: 40px;
  font-weight: 600;
`;

const Price = styled.h4`
  font-size: 2rem;
  line-height: 40px;
  font-weight: 600;
  margin: 0;
  position: relative;
  margin-top: 30px;
`;

const Span = styled.span`
  position: absolute;
  font-weight: 600;
  font-size: 1.25rem;
`;

const Card = styled(PTCard)`
  background-color: ${Colors.colorDarkOne};
`;

const SubscriptionCancelBadge = styled.div`
  width: fit-content;
  padding: 0 10px;
  border-radius: 10px;
  background-color: #f0a3aa;
`;

const SubscriptionActiveBadge = styled.div`
  width: fit-content;
  padding: 0 10px;
  border-radius: 10px;
  background-color: #0ef1db;
  color: black;
`;

const SubscriptionDiscountBadge = styled.div`
  width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #0ef1db;
  color: black;
`;

const INITIAL = 'initial';
const IN_PROGRESS = 'in-progress';
const ERROR = 'error';

const stripeKey = process.env.REACT_APP_STRIPE_PK;
const stripePromise = loadStripe(stripeKey);

const Subscription = ({ history }) => {
  const {
    data: { data: { subscription: userSubscription } = {} } = {},
    isLoading,
    refetch: refetchUserSubscriptionPlan,
  } = useQuery('user-subscription', getSubscription);

  const { data: { data: { card: savedCard } = {} } = {} } = useQuery(
    'user-saved-card',
    getSavedCard
  );

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [status, setPaymentStatus] = useState(INITIAL);
  const [cancellingSubscription, setSubscriptionCancelling] = useState(false);

  const plan = SubscriptionPlansConfig[userSubscription?.plan];

  const isFreePlan = plan?.id === FREE_PLAN;
  const isActive = userSubscription?.state?.status === 'active';
  const isPastDue = userSubscription?.state?.status === 'past_due';
  const isIncomplete = userSubscription?.state?.status === 'incomplete';
  const isIncompleteExpired = userSubscription?.state?.status === 'incomplete_expired';
  const isSubscriptionCancelling = userSubscription?.state?.cancel_at_period_end;

  // if discount applied
  const percentOff = userSubscription?.state?.percent_off;
  const currentPlanPrice =
    Number(userSubscription?.state?.unit_amount_decimal) / 100 ||
    SubscriptionPlansConfig[userSubscription?.plan]?.price;
  const discountedPrice = (((100 - percentOff) * currentPlanPrice) / 100).toFixed(2);

  useEffect(() => {
    const pay = getQueryParam('pay');
    if (pay) {
      setShowPaymentModal(true);
    }
  }, []);

  const handleOnCreditCardPayment = async () => {
    setPaymentStatus(IN_PROGRESS);
    try {
      const response = await createStripeSession();

      if (response.data) {
        const stripe = await stripePromise;
        stripe.redirectToCheckout({
          sessionId: response.data.sessionId,
        });
      }
    } catch (e) {
      setPaymentStatus(ERROR);
    }
  };

  const handleOnModalClose = () => {
    setShowPaymentModal(false);
    setPaymentStatus(INITIAL);
    history.replace({
      search: '',
    });
  };

  const handleStripePayment = () => {
    setPaymentStatus(IN_PROGRESS);
    performStripePayment({ subscriptionPlan: 'playtreks_copyright_unlimited_monthly_plan' })
      .then(() => {
        toast.success('Subscription payment successful!');
        setShowPaymentModal(false);
        history.replace({
          search: '',
        });
        refetchUserSubscriptionPlan();
      })
      .catch(() => {
        toast.error('Something went wrong. Please try again!');
      });
  };

  const handleCancelSubscription = async () => {
    if (window.confirm('Are you sure you want to cancel your subscription?')) {
      try {
        setSubscriptionCancelling(true);
        await cancelSubscription({
          subscriptionPlan: 'playtreks_copyright_unlimited_monthly_plan',
        });
        setSubscriptionCancelling(false);
        toast.success('Subscription cancelled successfully!');

        refetchUserSubscriptionPlan();
      } catch (e) {
        toast.error('Something went wrong. Please try again!');
        setSubscriptionCancelling(false);
      }
    }
  };

  const SubscriptionStatus = () => {
    if (isFreePlan || isIncompleteExpired) {
      return (
        <>
          You don't have any subscription plan. <br />
          Please subscribe to one of the plans below to get started.
        </>
      );
    }

    if (percentOff && !isSubscriptionCancelling) {
      return (
        <>
          <SubscriptionDiscountBadge>
            {percentOff}% discount{' '}
            <span aria-label="heart" alt="heart">
              💙
            </span>
          </SubscriptionDiscountBadge>
          <br />
          Your current subscription is {plan?.name}!
          <br />
          <span>{discountedPrice} € / month &nbsp;</span>
        </>
      );
    }

    if (isActive && !isSubscriptionCancelling) {
      return (
        <>
          <SubscriptionActiveBadge className="mb-2">Subscription active</SubscriptionActiveBadge>
          Your current subscription is {plan?.name}!
          <br />
          <span>{plan?.price} € / month</span>
        </>
      );
    }

    if (isSubscriptionCancelling) {
      return (
        <>
          <SubscriptionCancelBadge className="mb-2">
            Cancellation in progress
          </SubscriptionCancelBadge>
          <u>
            Your {plan?.name} subscription will cancel on{' '}
            {format(userSubscription?.state?.cancel_at * 1000, 'MMMM do yyyy HH:mm:ss')}.
          </u>
        </>
      );
    }

    if (isPastDue) {
      return (
        <>
          <SubscriptionCancelBadge className="mb-2">Past due</SubscriptionCancelBadge>
          <u>
            Your {plan?.name} subscription is past due. You need to complete the payment to acess
            the service.
          </u>
        </>
      );
    }

    if (isIncomplete) {
      return (
        <>
          <SubscriptionCancelBadge className="mb-2">Incomplete</SubscriptionCancelBadge>
          <u>
            Your {plan?.name} subscription plan payment was incomplete. Please complete the payment
            to access the service.
          </u>
        </>
      );
    }

    return (
      <>
        You don't have any subscription plan. <br />
        Please subscribe to one of the plans below to get started.
      </>
    );
  };

  return (
    <>
      <Modal showModal={showPaymentModal} onCloseRequest={handleOnModalClose}>
        {/* Choose payment method */}
        {status === INITIAL && (
          <>
            {!savedCard ? (
              <div className="p-4 text-white">
                <h3 className="mb-4">Please pick your payment method</h3>
                <PaymentMethod
                  onClick={handleOnCreditCardPayment}
                  className="p-3 d-flex justify-content-between"
                >
                  <div>credit card</div>
                  <CreditCardIcon />
                </PaymentMethod>
                {/* <PaymentMethod className="p-3 d-flex justify-content-between">
                    <div>paypal</div>
                    <PayPalIcon />
                  </PaymentMethod> */}
              </div>
            ) : (
              <div className="text-white">
                <div className="p-3 mt-3">
                  <h4>You are about to subscribe to Unlimited plan!</h4>
                </div>
                <br />

                <div className="row justify-content-center mt-3">
                  <PTButton
                    onClick={handleStripePayment}
                    label={`Pay with a ${savedCard?.brand} card ending with ${savedCard?.last4}`}
                    primary
                    medium
                  />

                  <br />
                </div>
              </div>
            )}
          </>
        )}

        {/* Payment status */}
        {status === IN_PROGRESS && (
          <div className="xy-flex-center col h-75">
            <SpinnerIcon />
          </div>
        )}
      </Modal>

      <Card className="my-3">
        {!isLoading ? (
          <div className="p-3 ">
            <SubscriptionStatus />
          </div>
        ) : (
          <div className="p-3 row justify-content-center">
            <SpinnerIcon />
          </div>
        )}
      </Card>

      <Card className="text-white">
        <div className="cards row justify-content-center">
          {isLoading ? (
            <div className="row xy-flex-center">
              <SpinnerIcon />
            </div>
          ) : (
            <div className="cards_item col-12 col-sm-8 col-md-6 col-lg-5">
              <div className="card_section">
                <Title>Unlimited plan</Title>
                <p>Create unlimited copyrights!</p>

                <Price>
                  €10 {'  '}
                  <Span className="text-muted">/ month</Span>
                </Price>

                <div className="py-3 row mt-3 xy-flex-center">
                  <div className="col-9">
                    {plan?.id !== UNLIMITED_PLAN ||
                    isPastDue ||
                    isIncomplete ||
                    isIncompleteExpired ? (
                      <PTButton
                        primary
                        label={
                          isPastDue ? 'Pay due amount' : isIncomplete ? 'Retry payment' : 'Buy now'
                        }
                        className="w-100"
                        onClick={() => setShowPaymentModal(true)}
                      />
                    ) : isSubscriptionCancelling ? (
                      <PTButton
                        primary
                        label="Subscription cancelled!"
                        className="w-100 opacity-50 cursor-default"
                        disabled
                      />
                    ) : (
                      <PTButton
                        outlined
                        label={cancellingSubscription ? 'Cancelling...' : 'Cancel subscription'}
                        className="w-100"
                        onClick={handleCancelSubscription}
                        endIcon={
                          cancellingSubscription ? (
                            <SpinnerIcon height={24} />
                          ) : (
                            <span aria-label="Cancel" alt="cancel">
                              ❌
                            </span>
                          )
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

export default Subscription;
