import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const TextAreaComponent = styled.div`
  flex-grow: 1;
  position: relative;
`;

export const TextArea = styled.textarea`
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  border: 0;
  border-radius: 10px;
  width: 100%;
  transition: border 200ms ease;
  background-color: ${Colors.colorDarkOne};
  color: ${Colors.white};
  padding: ${({ value, label }) =>
    label && value ? '0.625rem 0.75rem' : '1.25rem 0.75rem 0.625rem 0.75rem'};

  &:focus {
    border: 1px solid ${Colors.primary};
    box-shadow: 0;
    outline: 0;
  }
`;

export const Error = styled.p`
  font-size: 12px;
  line-height: 1.5;
  color: ${Colors.error};
  position: relative;
  height: 20px;
  margin: 5px 0 0 3px;
`;

export const Label = styled.label`
  position: absolute;
  visibility: ${({ value, label }) => (label && value ? 'visible' : 'hidden')};
  top: ${({ value, label }) => (label && value ? '4px' : '16px')};
  left: 12px;
  font-size: ${({ value, label }) => (label && value ? '12px' : '16px')};
  color: rgb(255, 255, 255, 0.63);
  margin: 0;
  z-index: 10;
  transition: top, font-size 200ms ease;
`;
