/* eslint-disable arrow-body-style */
import React from 'react';
import { Link } from 'react-router-dom';
import { GroupIcon, VectorStarIcon } from '../../assets/icons';

import PTButton from '../../components/Button/Button';
import './index.scss';

const MyfilesHeader = () => {
  return (
    <section className="header">
      <div className="dDecoration1">
        <div className="dd1a">+</div>
      </div>

      <div className="dShader" />
      <div className="row col justify-content-center align-items-center">
        <Link to="/copyright-song">
          <PTButton primary label="Add new file" />
        </Link>
      </div>
      <div className="dDecoration3">
        <VectorStarIcon alt="star" className="dd3star" />
        <img src={GroupIcon} alt="group" className="dd3group" />
      </div>
    </section>
  );
};

export default MyfilesHeader;
