import React from 'react';
// import PropTypes from 'prop-types';
import Button from './styles';

const PTButton = ({ label, startIcon, endIcon, ...rest }) => (
  <Button {...rest}>
    {startIcon ? <span className="mr-2">{startIcon}</span> : null}
    {label}
    {endIcon ? <span className="ml-2">{endIcon}</span> : null}
  </Button>
);

// PTButton.propTypes = {
//   /**
//    * Variant of a button
//    */
//   primary: PropTypes.bool,
//   /**
//    * How large should the button be?
//    */
//   size: PropTypes.oneOf(['small', 'medium', 'large']),
//   /**
//    * Button contents
//    */
//   label: PropTypes.string.isRequired,
// };

// PTButton.defaultProps = {
//   primary: false,
//   size: 'medium',
// };

export default PTButton;
