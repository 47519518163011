/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
// import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import CaretDownIcon from '../../assets/icons/icon-caret-down.svg';
import DefaultUserIcon from '../../assets/icons/user.svg';
import { getUserData } from '../../services/user';

const AUTH_URL = process.env.REACT_APP_AUTH_REDIRECT_URL;
const APP_URL = process.env.REACT_APP_APP_URL;

function UserProfile() {
  const [profileOpen, setProfileOpen] = useState(false);
  const node = useRef();
  const { data: { data: user = {} } = {} } = useQuery('user', getUserData);

  useEffect(() => {
    if (user) {
      localStorage.setItem('userName', `${user?.firstname ?? ''}  ${user?.lastname ?? ''}`.trim());
    }
  }, [user]);

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setProfileOpen(false);
  };

  useEffect(() => {
    if (profileOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [profileOpen]);

  return (
    <div
      className="user-profile"
      style={{ cursor: 'pointer' }}
      ref={node}
      onClick={() => setProfileOpen(!profileOpen)}
      onKeyPress={() => setProfileOpen(!profileOpen)}
      role="button"
      tabIndex={0}
    >
      {/* <label className="user-profile-name">{reduxUsername}</label> */}
      <img
        src={user?.profilePicture ? user.profilePicture : DefaultUserIcon}
        className="user-profile-avatar user-profile-avatar-desktop"
        alt="avatar"
        onClick={() => setProfileOpen(!profileOpen)}
      />
      <a href={`${APP_URL}/artistDashboard/profile`}>
        <img
          src={DefaultUserIcon}
          className="user-profile-avatar user-profile-avatar-mobile"
          alt="avatar"
        />
      </a>
      <img
        src={CaretDownIcon}
        className={`user-profile-caret ${profileOpen ? 'up' : ''} `}
        alt="caret"
        onClick={() => setProfileOpen(!profileOpen)}
      />
      <div className={`user-profile-dropdown ${profileOpen ? 'show' : ''} `}>
        <ul>
          <li>
            <a href={`${APP_URL}/artistDashboard/profile`}>
              <div className="user-profile-dropdown-inner">Account settings</div>
            </a>
          </li>
          <li>
            <a href={`${APP_URL}/artistDashboard/subscriptions`}>
              <div className="user-profile-dropdown-inner">Subscription settings</div>
            </a>
          </li>
          <li>
            <a href="https://marketplace.playtreks.com/wallet">
              <div className="user-profile-dropdown-inner">Wallet details</div>
            </a>
          </li>
          <li>
            <a href={`${AUTH_URL}/logout`}>
              <div className="user-profile-dropdown-inner">Log out</div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default UserProfile;
