/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { TrashIcon } from '../../assets/icons';

const getColumns = ({ onDeleteRow }) => [
  {
    Header: 'Title',
    accessor: 'title',
  },

  {
    Header: 'File type',
    accessor: 'fileType',
  },
  {
    Header: 'File name',
    id: 'file',
    accessor: ({ fileName, file }) => ({ fileName, file }),
    Cell: ({ value: { fileName, file } }) => (
      <a target="_blank" href={file} rel="noreferrer" title={fileName}>
        {fileName}
      </a>
    ),
  },
  {
    Header: 'Date created',
    accessor: 'createdAt',
    Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy'),
  },
  {
    Header: 'Action',
    accessor: ({ _id }) => ({ fileId: _id }),
    disableSortBy: true,
    Cell: ({ value: { fileId } }) => (
      <div className="d-inline-flex justify-content-center align-items-center" title="">
        <Link className="table-action-link mr-2" to={`/copyrighted-file/${fileId}`}>
          Details
        </Link>{' '}
        |{' '}
        <span
          rol="button"
          onClick={onDeleteRow.bind(this, fileId)}
          className="ml-2 cursor-pointer"
          title="Delete file"
        >
          <TrashIcon width={20} height={20} />
        </span>
      </div>
    ),
  },
];

export default getColumns;
