/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  FacebookShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  TelegramIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

import { PTTransparentIcon, SpinnerIcon, SecurityIcon } from '../../assets/icons';

import { getCopyrightedFile } from '../../services/copyright';
import Colors from '../../styles/Colors';

const Wrapper = styled.div`
  background: ${Colors.colorDarkOne};
  border-radius: 14px;
  overflow-wrap: break-word;
`;

const Action = styled.div`
  color: ${Colors.colorLightTwo};

  a {
    color: ${Colors.colorLightTwo};
  }

  &:hover {
    color: ${Colors.primary};

    a {
      color: ${Colors.primary};
    }
  }
`;
const Divider = styled.div`
  width: 100%;
  background: ${Colors.primary};
  opacity: 0.4;
  height: 2px;
`;

const Image = styled.img`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
`;

const CopyrightPublicSong = ({ match }) => {
  const { id: fileId } = match.params;
  // eslint-disable-next-line no-unused-vars
  const { data: { data: copyrightedFile = {} } = {}, isLoading } = useQuery(
    fileId,
    getCopyrightedFile.bind(this, fileId)
  );

  if (isLoading) {
    return (
      <div className="row align-items-center justify-content-center 100vh h-100vh w-100">
        <SpinnerIcon width={100} height={100} />
      </div>
    );
  }
  return (
    <div className="mt-3">
      <div className="row mx-0 justify-content-center align-items-center mb-4 ">
        <Image width={200} src={PTTransparentIcon} alt="playtreks-logo" className="img-fluid" />
      </div>

      <div className="row mx-0 justify-content-center">
        <Wrapper className="col-11 col-lg-9 col-xl-8 text-center p-3">
          <h2 className="text-center text-white font-weight-bold pt-4">{copyrightedFile?.title}</h2>

          {/* audio player */}
          <div className="p-3 ">
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio
              controls
              controlsList="nodownload"
              className="col-md-4 embed-responsive d-inline"
            >
              <source src={copyrightedFile?.file} type="audio/mpeg" />
              <source src={copyrightedFile?.file} type="audio/ogg" />
              Your browser does not support the audio element.
            </audio>
          </div>

          {/* Created by */}
          <div className="pt-3 text-center">
            <div className="color-gray10 font-weight-bold pb-2  ">CREATED BY:</div>
            <h3 className=" text-white ">{copyrightedFile?.createdBy}</h3>
          </div>

          <Divider className="mb-3" />

          {/* External links */}
          <Action>
            <Link to="/">
              <Image width={50} src={SecurityIcon} alt="protection icon" className="img-fluid" />
              {copyrightedFile?.fileType === 'song' ? (
                <span className=" font-weight-bold pl-2">
                  This song was registered and copyrighted using copyright.playtreks.com
                </span>
              ) : (
                <span className=" font-weight-bold pl-2">
                  This production was registered and copyrighted using copyright.playtreks.com
                </span>
              )}
            </Link>
          </Action>

          <Link to={`/verify/${fileId}`}>
            {copyrightedFile?.fileType === 'song' ? (
              <h2 className=" font-weight-bold mt-4">CLICK HERE TO VERIFY THIS SONG COPYRIGHT</h2>
            ) : (
              <h2 className=" font-weight-bold mt-4">
                CLICK HERE TO VERIFY THIS PRODUCTION COPYRIGHT
              </h2>
            )}
          </Link>

          <Divider className="mt-3" />

          {/* share */}
          <div className="pt-4 text-center">
            {copyrightedFile?.fileType === 'song' ? (
              <h4 className="color-gray10 font-weight-bold pb-2  ">SHARE THIS SONG:</h4>
            ) : (
              <h4 className="color-gray10 font-weight-bold pb-2 ">SHARE THIS PRODUCTION:</h4>
            )}
            {copyrightedFile?.fileType === 'song' ? (
              <p className=" text-white">
                Share this song with your friends by using the buttons below:
              </p>
            ) : (
              <p className=" text-white">
                Share this production with your friends by using the buttons below:
              </p>
            )}
            <div className="row justify-content-center">
              <FacebookShareButton
                className="pr-2"
                url={`${window.location.origin}/song/${fileId}`}
              >
                <FacebookIcon width={40} height={40} round />
              </FacebookShareButton>

              <TwitterShareButton className="pr-2" url={`${window.location.origin}/song/${fileId}`}>
                <TwitterIcon width={40} height={40} round />
              </TwitterShareButton>

              <PinterestShareButton
                className="pr-2"
                url={`${window.location.origin}/song/${fileId}`}
              >
                <PinterestIcon width={40} height={40} round />
              </PinterestShareButton>

              <WhatsappShareButton
                className="pr-2"
                url={`${window.location.origin}/song/${fileId}`}
              >
                <WhatsappIcon width={40} height={40} round />
              </WhatsappShareButton>

              <TelegramShareButton url={`${window.location.origin}/song/${fileId}`}>
                <TelegramIcon width={40} height={40} round />
              </TelegramShareButton>
            </div>
          </div>
        </Wrapper>
      </div>

      <Action>
        <Link to="/">
          <p className="text-center overflow-hidden">Powered by copyright.playtreks.com</p>
        </Link>
      </Action>
    </div>
  );
};

export default CopyrightPublicSong;
