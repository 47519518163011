/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Hamburger from '../../assets/icons/Cheeseburger.svg';
import Cross from '../../assets/icons/X.svg';
import AddToQueueIcon from '../../assets/icons/add_to_queue.svg';
import HelpCenterIcon from '../../assets/icons/helpcenter.png';
import UserIcon from '../../assets/icons/user_gray.png';
import './index.scss';
import { CopyrightMenu, Support, UserMenu } from '../sidemenu/menuConfig';
import AppSwitcher from '../AppSwitcher/AppSwitcher';

const AUTH_URL = process.env.REACT_APP_AUTH_REDIRECT_URL;
const APP_URL = process.env.REACT_APP_APP_URL;

export default function MobileMenuBurger() {
  function handleOpenMenu() {
    document.querySelector('.mobileMenu-overlay').classList.remove('d-none');
    document.querySelector('body').classList.add('overflow-hidden');
  }

  return (
    <div className="mobileMenu-burger" onClick={handleOpenMenu}>
      <img src={Hamburger} alt="menu" />
    </div>
  );
}

export function MobileMenu() {
  const [selectedDropDown, setSelectedDropDown] = useState('copyright');

  const handleDropDownValueChange = (event) => {
    const { value } = event.target;
    setSelectedDropDown(event.target.value);

    if (value === 'analytics') {
      window.location.href = `${APP_URL}/artistDashboard`;
    }
    if (value === 'distribution') {
      window.location.href = `${APP_URL}/artistDashboard/myReleases`;
    }
    if (value === 'marketplace') {
      window.location.href = 'https://marketplace.playtreks.com/';
    }
  };
  function handleCloseMenu() {
    document.querySelector('.mobileMenu-overlay').classList.add('d-none');
    document.querySelector('body').classList.remove('overflow-hidden');
  }

  return (
    <div className="mobileMenu-overlay d-none ">
      <img src={Cross} alt="close" className="mobileMenu-x" onClick={handleCloseMenu} />

      <div className="mobileMenu">
        <AppSwitcher
          handleDropDownValueChange={handleDropDownValueChange}
          selectedDropDown={selectedDropDown}
        />
        {selectedDropDown === 'copyright' && (
          <>
            <MobileMenuSection
              headerIcon={AddToQueueIcon}
              headerTitle="copyright"
              menus={CopyrightMenu}
              handleCloseMenu={() => handleCloseMenu()}
            />
            <br />
            <MobileMenuSection
              headerIcon={HelpCenterIcon}
              headerTitle="support"
              menus={Support}
              handleCloseMenu={() => handleCloseMenu()}
            />
            <br />
            <MobileMenuSection
              headerIcon={UserIcon}
              headerTitle="my account"
              menus={UserMenu}
              handleCloseMenu={() => handleCloseMenu()}
            />
          </>
        )}
      </div>
    </div>
  );
}

function MobileMenuSection({ headerIcon, headerTitle, menus, handleCloseMenu }) {
  return (
    <div className="mobileMenu-section">
      {headerTitle && (
        <label className="mobileMenu-header">
          <img src={headerIcon} alt="header" />
          {headerTitle}
        </label>
      )}
      <ul>
        {menus.map((item, index) =>
          item.url === 'https://support.playtreks.com/' ||
          item.url === `${APP_URL}/artistDashboard/profile` ||
          item.url === `${APP_URL}/artistDashboard/subscriptions` ||
          item.url === `${AUTH_URL}/logout` ||
          item.url === 'https://marketplace.playtreks.com/wallet' ? (
            <a
              href={item.url}
              key={index}
              target={item.url === 'https://support.playtreks.com/' ? '_blank' : ''}
              onClick={() => handleCloseMenu()}
              rel="noreferrer"
            >
              <li>{item.label}</li>
            </a>
          ) : (
            <Link to={item.url} key={index} onClick={() => handleCloseMenu()}>
              <li>{item.label}</li>
            </Link>
          )
        )}
      </ul>
    </div>
  );
}
