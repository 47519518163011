import axios from 'axios';
import { getJWT } from '../shared/utils';

// eslint-disable-next-line prefer-destructuring
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export function getUserData() {
  const url = `${API_BASE_URL}/user/getUserData`;
  const config = {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  };

  return axios.get(url, config);
}
